import "./assets/www.css";

import themeColors from './themeColors.json';
import 'iconify-icon';
import 'phoenix_html';
import { Socket } from 'phoenix';
import { LiveSocket } from 'phoenix_live_view';
// @ts-ignore-next-line
import topbar from 'topbar';

// @ts-ignore-next-line
let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
let liveSocket = new LiveSocket("/live", Socket, {params: {_csrf_token: csrfToken}})

liveSocket.connect()

topbar.config({barColors: {0: themeColors.primary}, shadowColor: 'rgba(0, 0, 0, 0.3)'})
window.addEventListener('phx:page-loading-start', () => topbar.show());
window.addEventListener('phx:page-loading-stop', () => topbar.hide());

// @ts-ignore-next-line
window.liveSocket = liveSocket
